const getComponentImport = (name: string, path: string) => () =>
    import(`@/components/blocks/${path}${name}.vue`);
const allComponents = [
    {
        path: '',
        components: [
            'HeaderBlock',
            'CardList',
            'CardsListWithIcon',
            'Columns',
            'ContactsMaps',
            'ContactsTabs',
            'ContactsText',
            'Video',
            'TabsWithImagesAndText',
            'TabsWithOneImageAndText',
            'TabsWithTable',
            'ImageAndText',
            'ImageOneBlock',
            'ImagesCardsBlock',
            'Colors',
            'BaseColors',
            'AdditionalOptions',
            'InstagramPosts',
            'WorksList',
            'Blog',
            'QuestionAnswer',
            'Products',
            'SliderBlock',
            'ImageComponent',
            'BreadCrumbs',
            'Form',
            'FormColorNotFound',
            'TriggerBlock',
            'PricesColumn',
            'BaseColorsModal',
            'TriggerBlockImages',
            'TriggerBlockImagesBorder',
            'UsefulArticles',
            'MaterialsAndColors',
            'SliderExampleWork',
            'GalleryWithText',
            'RelatedSliders',
            'Header',
            'Footer',
            'TextBlock',
            'ExpertiseAndArticles',
            'DynamicHeader',
            'MarginBlock',
            'SimpleSlider',
            'SimpleForm',
            'TabsSlider',
            'TabsWithBlocks',
            'SimpleForm',
            'TriggerBlockWithSixPicture',
            'CardsInTwoColumns',
            // 'CustomComponent',
            'NotFoundBlock'
        ]
    },
    {
        path: 'blog/',
        components: [
            'ParagraphWithHeadings',
            'BlogMainText',
            'BlogPicsInColumns',
            'HeaderFullImage',
            'BlogHeader',
            'BlogContentNav',
            'BlogSocial',
            'BlogTableContents',
            'PictureWithText',
            'BlogTwoColumnsTableFirst',
            'BlogTwoColumnsTableSecond',
            'ImageWithText',
            'TextFramed',
            'HeaderWithPic',
            'Subscribe',
            'BlogThreeColumnsTable',
            'SocialLinks',
            'BlogFourPicturesWithText',
            'BlogQuotes',
            'BlogVideo'
        ]
    },
    {
        path: 'blog/additional/',
        components: [
            'AdditionalText',
            'PictureWithLink',
            'FeedbackFormSidebar',
            'SimilarArticles'
        ]
    }
];

let components = {};

allComponents.forEach(group => {
    group.components.forEach(name => {
        components[name] = getComponentImport(name, group.path);
    });
});

let additionalComponents = {};

export const getAdditionalComponents = () => {
    allComponents[2].components.forEach(name => {
        additionalComponents[name] = getComponentImport(name, allComponents[2].path);
    });
    return additionalComponents;
};

export const getComponentsMap = () => {
    let componentsMap = {};
    allComponents.forEach(group => {
        group.components.forEach(name => {
            componentsMap[name] = group.path;
        });
    });
    return componentsMap;
};

export const getComponentsListWithGroups = () => {
    let componentsList: { path: string; name: string }[] = [];
    allComponents.forEach(group => {
        group.components.forEach(name => {
            componentsList.push({
                path: group.path,
                name
            });
        });
    });
    return componentsList;
};

export default components;
