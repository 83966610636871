import { Permissions } from '@/components/editor/config/acl/pages';

export enum AvailableTypes {
    string,
    editor,
    checkbox,
    boolean,
    image,
    galleryImage,
    form,
    palette,
    color,
    array,
    arrayOfType,
    object,
    blockSideImgAndButton,
    select,
    group,
    apiSource,
    component,
    componentOptions,
    componentsList,
    fullComponentsList,
    additionalComponent,
    password,
    menuLink,
    deepMenuLink,
    id,
    pages,
    textarea,
    date,
    tag,
    multiselect,
    pagesMultiselect,
    code,
    json,
    number,
    valueEUnit,
    link
}

export enum BaseTypes {
    string,
    editor,
    image,
    galleryImage,
    color,
    select,
    group,
    palette,
    password,
    id,
    code,
    componentsList,
    fullComponentsList,
    pages,
    textarea,
    tag,
    date,
    multiselect,
    pagesMultiselect
}

export enum AvailableGroups {
    Обложка,
    Хедер,
    Футер,
    Изображение,
    Заголовок,
    Текстовый_блок,
    Галерея,
    Формы,
    Плитка,
    Этапы,
    Видео,
    Цвета,
    Список,
    Тексты,
    Вкладки,
    Слайдер,
    Услуги,
    Цены,
    Сайдбар,
    Таблицы,
    Блог,
    Другое
}

export default {
    component: {
        name: {
            type: AvailableTypes.string
        },
        active: {
            type: AvailableTypes.boolean,
            default: true
        },
        options: {
            type: AvailableTypes.componentOptions
        }
    },
    additionalComponent: {
        name: {
            type: AvailableTypes.string
        },
        active: {
            type: AvailableTypes.boolean,
            default: true
        },
        options: {
            type: AvailableTypes.componentOptions
        }
    },
    link: {
        href: {
            type: AvailableTypes.string,
            title: 'Ссылка',
            default: ''
        },
        blank: {
            type: AvailableTypes.boolean,
            title: 'В новом окне',
            default: false
        }
    },
    menuLink: {
        name: {
            title: 'Имя',
            type: AvailableTypes.string
        },
        href: {
            title: 'Ссылка',
            type: AvailableTypes.string
        },
        targetBlank: {
            title: 'Открывать ссылку в новом окне',
            type: AvailableTypes.boolean
        },
        component: {
            title: 'Блок',
            type: AvailableTypes.componentsList
        }
    },
    deepMenuLink: {
        name: {
            title: 'Имя',
            type: AvailableTypes.string
        },
        href: {
            title: 'Ссылка',
            type: AvailableTypes.string
        },
        targetBlank: {
            title: 'Открывать ссылку в новом окне',
            type: AvailableTypes.boolean
        },
        component: {
            title: 'Блок',
            type: AvailableTypes.componentsList
        },
        submenu: {
            title: 'Подменю',
            type: AvailableTypes.array,
            item: {
                icon: {
                    title: 'Иконка',
                    type: AvailableTypes.image,
                    default: {
                        src: ''
                    }
                },
                head: {
                    title: 'Заголовок',
                    type: AvailableTypes.object,
                    item: {
                        name: {
                            title: 'Имя',
                            type: AvailableTypes.string
                        },
                        href: {
                            title: 'Ссылка',
                            type: AvailableTypes.string
                        },
                        targetBlank: {
                            title: 'Открывать ссылку в новом окне',
                            type: AvailableTypes.boolean
                        }
                    }
                },
                links: {
                    title: 'Ссылки',
                    type: AvailableTypes.array,
                    item: {
                        name: {
                            title: 'Имя',
                            type: AvailableTypes.string
                        },
                        href: {
                            title: 'Ссылка',
                            type: AvailableTypes.string
                        },
                        targetBlank: {
                            title: 'Открывать ссылку в новом окне',
                            type: AvailableTypes.boolean
                        }
                    }
                }
            }
        }
    },
    color: {
        name: {
            title: 'Имя',
            type: AvailableTypes.string
        },
        hex: {
            title: 'HEX цвет',
            type: AvailableTypes.string,
            default: '#000000'
        },
        img: {
            title: 'Изображение',
            type: AvailableTypes.image,
            default: {}
        }
    },
    group: {
        options: {
            type: AvailableTypes.object,
            item: {
                name: {
                    type: AvailableTypes.string
                },
                slug: {
                    type: AvailableTypes.string
                }
            }
        },
        _id: {
            type: AvailableTypes.string
        }
    },
    image: {
        src: {
            title: 'src',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        small: {
            title: 'small',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        thumbnail: {
            title: 'thumbnail',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        medium: {
            title: 'medium',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        alt: {
            title: 'alt',
            type: AvailableTypes.string
        },
        title: {
            title: 'title',
            type: AvailableTypes.string
        },
        loop: {
            title: 'loop',
            type: AvailableTypes.boolean
        },
        playOnScroll: {
            title: 'Play on scroll',
            type: AvailableTypes.boolean
        }
    },
    galleryImage: {
        size: {
            type: AvailableTypes.string
        },
        src: {
            title: 'src',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        small: {
            title: 'small',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        thumbnail: {
            title: 'thumbnail',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        medium: {
            title: 'medium',
            type: AvailableTypes.string,
            default: '/placeholder-600x400.png'
        },
        alt: {
            title: 'alt',
            type: AvailableTypes.string
        },
        title: {
            title: 'title',
            type: AvailableTypes.string
        },
        margin: {
            title: 'Отступ',
            type: AvailableTypes.boolean
        },
        loop: {
            title: 'loop',
            type: AvailableTypes.boolean
        },
        playOnScroll: {
            title: 'Play on scroll',
            type: AvailableTypes.boolean
        }
    },
    palette: {
        bg: {
            title: 'bg',
            type: AvailableTypes.string
        },
        icon: {
            title: 'icon',
            type: AvailableTypes.string
        },
        trigger: {
            title: 'trigger',
            type: AvailableTypes.string
        },
        text: {
            title: 'text',
            type: AvailableTypes.string
        }
    },
    form: {
        submitButtonText: {
            type: AvailableTypes.string,
            title: 'Текст кнопки',
            default: 'Отправить'
        },
        from: {
            type: AvailableTypes.string,
            title: 'Отправитель'
        },
        to: {
            type: AvailableTypes.string,
            title: 'Получатель'
        },
        fields: {
            type: AvailableTypes.array,
            title: 'поля',
            item: {
                name: {
                    type: AvailableTypes.string,
                    title: 'name'
                },
                title: {
                    type: AvailableTypes.editor,
                    title: 'Имя',
                    default: 'Имя поля'
                },
                type: {
                    type: AvailableTypes.select,
                    values: [
                        {
                            text: 'Текстовое поле',
                            value: 'text',
                            selected: true
                        },
                        {
                            text: 'Текстовая область',
                            value: 'textarea'
                        },
                        {
                            text: 'Список',
                            value: 'select'
                        },
                        {
                            text: 'Чекбокс',
                            value: 'checkbox'
                        },
                        {
                            text: 'Радио',
                            value: 'radio'
                        }
                    ],
                    title: 'Тип поля'
                },
                validation: {
                    type: AvailableTypes.select,
                    values: [
                        {
                            text: 'Имя / Фамилия',
                            value: 'name',
                            selected: true
                        },
                        {
                            text: 'Email',
                            value: 'email'
                        },
                        {
                            text: 'Телефон',
                            value: 'phone'
                        }
                    ],
                    title: 'Тип валидатора'
                },
                fullWidth: {
                    'title': 'На всю ширину',
                    'type': AvailableTypes.boolean
                },
                required: {
                    type: AvailableTypes.boolean,
                    title: 'Обязательное'
                }
            }
        }
    },
    blockSideImgAndButton: {
        head: {
            type: AvailableTypes.editor,
            title: 'Заголовок'
        },
        text: {
            type: AvailableTypes.editor,
            title: 'Текст'
        },
        leftImg: {
            type: AvailableTypes.image,
            title: 'Картинка слева'
        },
        rightImg: {
            type: AvailableTypes.image,
            title: 'Картинка справа'
        },
        buttonText: {
            type: AvailableTypes.string,
            title: 'Текст кнопки'
        },
        additionalClass: {
            type: AvailableTypes.string,
            title: 'Класс-обёртка блока'
        },
        rotateImage: {
            type: AvailableTypes.boolean,
            title: 'Поворот картинки справа'
        }
    }
};

export interface ComponentMeta {
    title: string;
    options:
        | {
              [optionName: string]:
                  | ComponentMetaOption
                  | ComponentMetaOptionArray
                  | ComponentMetaOptionObject
                  | ComponentMetaOptionSelect
                  | ComponentMetaOptionArrayOfType
                  | ComponentMetaOptionComponent
                  | ComponentMetaOptionComponentOptions;
          }
        | {
              type?: AvailableTypes;
              default?: any;
          }
        | {
              type?: AvailableTypes.componentOptions;
              componentName: string;
              default?: any;
          };
    group?: AvailableGroups;
    inner?: boolean;
    additional?: boolean;
    style?: { [optionName: string]: string | number };
}

export interface ComponentMetaOption {
    title?: string;
    type: AvailableTypes;
    default?: any;
    hidden?: boolean;
    description?: string;
    language?: string;
}

export interface ComponentMetaOptionSelect extends ComponentMetaOption {
    values:
        | {
              [position: number]: ComponentMetaOptionSelectValue;
          }
        | OptionApiSource;
}

export interface ComponentMetaOptionArray extends ComponentMetaOption {
    item: {
        [optionName: string]: ComponentMetaOption;
    };
    limit?: number;
}

export interface ComponentMetaOptionArrayOfType extends ComponentMetaOption {
    item: {
        type: AvailableTypes;
    };
}

export interface ComponentMetaOptionObject extends ComponentMetaOptionArray {}

export interface ComponentMetaOptionComponent extends ComponentMetaOption {
    componentName?: string;
}

export interface ComponentMetaOptionComponentOptions extends ComponentMetaOption {
    componentName?: string;
}

interface ComponentMetaOptionSelectValue {
    text: string;
    value: any;
    selected?: boolean;
}

export interface Option {
    type: AvailableTypes;
}

type KeysPermissions = keyof typeof Permissions;

export interface OptionApiSource extends Option {
    path: string;
    method: 'get' | 'post';
    acl?: (permissions: typeof Permissions[KeysPermissions][]) => boolean;
    conditions?: any;
    mappings?: {
        value: string;
        text: string;
    };
}

export interface OptionSelect extends Option {
    values: [] | OptionApiSource;
}
